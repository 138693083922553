import baseWretch from 'wretch';
import useWorkspace from './hooks/useWorkspace';
import QueryStringAddon from 'wretch/addons/queryString';

//
// Wretch client set up
//

// TODO: Maybe workspaceId can be cached into a variable and passed as a parameter.
// We'll see (Vova)
const workspaceMiddleware = (next) => (url, opts) => {
  opts.headers = { ...opts.headers, 'Workspace-Id': useWorkspace() };

  return next(url, opts);
};

// TODO: Create this wrapper so we don't need touch all existing functions
// I think we can move more repeatable code into this wrapper later. We'll see (Vova)
const wretch = (args) =>
  baseWretch(args).addon(QueryStringAddon).middlewares([workspaceMiddleware]);

//
// Functions
//

export function createPhoneNumber(chatbotId) {
  return new Promise(function(resolve) {
    wretch(`/api/v1/chatbots/${chatbotId}/phone_numbers`)
      .post()
      .res(resolve);
  });
}

export function getBillingPlans() {
  return new Promise(function(resolve, reject) {
    wretch('/api/v1/billing/plans')
      .get()
      .json(resolve)
      .catch(reject);
  });
}

export function changeBillingPlan(planId) {
  return new Promise(function(resolve, reject) {
    wretch('/api/v1/billing/subscription_transitions')
      .post({ current_plan_id: planId })
      .res(resolve)
      .catch(reject);
  });
}

export function getBillingActiveSubscription() {
  return new Promise(function(resolve, reject) {
    wretch('/api/v1/billing/active_subscription')
      .get()
      .json(resolve)
      .catch(reject);
  });
}

export function cancelBillingPlanDowngrade(url) {
  return new Promise(function(resolve, reject) {
    wretch(url)
      .delete()
      .res(resolve)
      .catch(reject);
  });
}

export function getOutboundLists(experienceUuid) {
  return new Promise(function(resolve, reject) {
    wretch(`/api/v1/experiences/${experienceUuid}/outbound_lists`)
      .get()
      .json(resolve)
      .catch(reject);
  });
}

export function createOutboundList(experienceUuid, phoneNumbers) {
  return new Promise(function(resolve, reject) {
    wretch(`/api/v1/experiences/${experienceUuid}/outbound_lists`)
      .post({ phone_numbers: phoneNumbers })
      .res(resolve)
      .catch(reject);
  });
}

export function getAutopilotSessions(autopilotSessionId) {
  return new Promise(function(resolve, reject) {
    wretch(
      `/api/v1/autopilot_sessions/${autopilotSessionId}/autopilot_responses`,
    )
      .get()
      .json(resolve)
      .catch(reject);
  });
}

export function getAutopilotResponses(params) {
  return new Promise(function(resolve, reject) {
    wretch('/api/v1/autopilot_responses')
      .query(params)
      .get()
      .json(resolve)
      .catch(reject);
  });
}

export function approveAutopilotResponse(autopilotId, params = {}) {
  return new Promise(function(resolve, reject) {
    wretch(`/api/v1/autopilot_responses/${autopilotId}/approve`)
      .post(params)
      .res(resolve)
      .catch(reject);
  });
}

export function ignoreAutopilotResponse(autopilotId, params = {}) {
  return new Promise(function(resolve, reject) {
    wretch(`/api/v1/autopilot_responses/${autopilotId}/ignore`)
      .post(params)
      .res(resolve)
      .catch(reject);
  });
}

export function ignoreAllInitialResponses() {
  return new Promise(function(resolve, reject) {
    wretch('/api/v1/autopilot_responses/ignore_initial_messages')
      .post()
      .res(resolve)
      .catch(reject);
  });
}

export function getEmbedCode(params) {
  return new Promise(function(resolve, reject) {
    wretch('/api/v1/embeds').post(params).json(resolve).catch(reject);
  });
}

export function getAllExperiencesList() {
  return new Promise(function(resolve, reject) {
    wretch('/api/v1/experiences')
      .get()
      .json((json) => {
        resolve(json.data.map((item) => item.attributes));
      })
      .catch(reject);
  });
}

export function getExperiencesList(params) {
  return new Promise(function(resolve, reject) {
    wretch('/api/v1/references/experiences')
      .query(params)
      .get()
      .json((json) => {
        resolve(json.data.map((item) => item.attributes));
      })
      .catch(reject);
  });
}

export function getExperiencesListFromEmailBuilder(host, auth, workspaceId) {
  return function(params) {
    return new Promise(function(resolve, reject) {
      baseWretch(`${host}/api/v1/references/experiences`)
        .addon(QueryStringAddon)
        .headers({'Workspace-Id': workspaceId })
        .query({...params, ...auth})
        .get()
        .json((json) => {
          resolve(json.data.map((item) => item.attributes));
        })
        .catch(reject);
    });
  };
}

export function getBlocksList(experienceId, params) {
  return new Promise(function(resolve, reject) {
    wretch(`/api/v1/references/experiences/${experienceId}/blocks`)
      .query(params)
      .get()
      .json((json) => {
        resolve(json.data.map((item) => item.attributes));
      })
      .catch(reject);
  });
}

export function getBlocksListFromEmailBuilder(host, auth, workspaceId) {
  return function(experienceId, params) {
    return new Promise(function(resolve, reject) {
      baseWretch(`${host}/api/v1/references/experiences/${experienceId}/blocks`)
        .addon(QueryStringAddon)
        .headers({'Workspace-Id': workspaceId })
        .query({...params, ...auth})
        .get()
        .json((json) => {
          resolve(json.data.map((item) => item.attributes));
        })
        .catch(reject);
    });
  };
}

export function deleteAction(url) {
  return new Promise(function(resolve, reject) {
    wretch(url).delete().res(resolve).catch(reject);
  });
}

export function deleteEvent(url) {
  return new Promise(function(resolve, reject) {
    wretch(url).delete().res(resolve).catch(reject);
  });
}

export function createMergeField(params) {
  return new Promise(function(resolve, reject) {
    wretch('/api/v1/references')
      .post(params)
      .json((json) => {
        const { name } = json;
        return resolve({
          ...json,
          name: name?.length > 35 ?
            `${name.slice(0, 32)}...` :
            name,
        });
      })
      .catch(reject);
  });
};

export function getCreateMergeFieldApiCall(host, auth) {
  return function(params) {
    return new Promise(function(resolve, reject) {
      wretch(`${host}/api/v1/references`)
        .post({...params, ...auth})
        .json((json) => {
          const { name } = json;
          return resolve({
            ...json,
            name: name?.length > 35 ?
              `${name.slice(0, 32)}...` :
              name,
          });
        })
        .catch(reject);
    });
  };
}

export function getReferencesList(blockId) {
  return new Promise(function(resolve, reject) {
    wretch(`/api/v1/references/blocks/${blockId}/list`)
      .get()
      .json((json) => resolve(json))
      .catch(reject);
  });
}

export function getReferencesEventsList() {
  return new Promise(function(resolve, reject) {
    wretch('/api/v1/event_references')
      .get()
      .json((json) => resolve(json))
      .catch(reject);
  });
}

export function createResponseViewColumn(params, viewId) {
  return new Promise(function(resolve, reject) {
    wretch(`/api/v1/response_views/${viewId}/columns`)
      .post(params)
      .res(resolve)
      .catch(reject);
  });
}

export function createResponseViewColumns(viewId, params) {
  return new Promise(function(resolve, reject) {
    wretch(`/api/v1/response_views/${viewId}/columns/batch`)
      .post(params)
      .res(resolve)
      .catch(reject);
  });
}

export function deleteResponseViewColumns(viewId, params) {
  return new Promise(function(resolve, reject) {
    wretch(`/api/v1/response_views/${viewId}/columns/batch`)
      .json(params)
      .delete()
      .res(resolve)
      .catch(reject);
  });
}

export function getMergeFields(blockId) {
  const prepareResponse = (json) => {
    return json.map((ref) => ({title: ref.name, value: ref.data, type: ref.type, available_types: ref.available_types}));
  };

  return new Promise(function(resolve, reject) {
    wretch(`/api/v1/references/blocks/${blockId}/list`)
      .get()
      .json((json) => {
        resolve(prepareResponse(json));
      })
      .catch(reject);
  });
}

export function getMergeFieldsFromEmailBuilder(host, auth, workspaceId) {
  return function(blockId) {
    const prepareResponse = (json) => {
      return json.map((ref) => ({title: ref.name, value: ref.data, type: ref.type}));
    };

    return new Promise(function(resolve, reject) {
      baseWretch(`${host}/api/v1/references/blocks/${blockId}/list`)
        .addon(QueryStringAddon)
        .headers({'Workspace-Id': workspaceId })
        .query(auth)
        .get()
        .json((json) => {
          resolve(prepareResponse(json));
        })
        .catch(reject);
    });
  };
}

export function getDataFieldsByType(blockId, type) {
  const prepareResponse = (json) => {
    return json
      .filter((ref) => ref.available_types && ref.available_types.includes(type))
      .map((ref) => ({title: ref.name, value: ref.data}));
  };

  return new Promise(function(resolve, reject) {
    wretch(`/api/v1/references/blocks/${blockId}/list`)
      .get()
      .json((json) => {
        resolve(prepareResponse(json, type));
      })
      .catch(reject);
  });
}

export function getCustomMergeFields(fields) {
  return new Promise(function(resolve) {
    resolve(fields);
  });
}

export function createWhitelabelDomain(params) {
  return new Promise(function(resolve, reject) {
    wretch('/api/v1/whitelabel_domains')
      .post(params)
      .json(resolve)
      .catch(reject);
  });
}

export function deleteWhitelabelDomain(id) {
  return new Promise(function(resolve, reject) {
    wretch(`/api/v1/whitelabel_domains/${id}`)
      .delete()
      .res(resolve)
      .catch(reject);
  });
}

export function setWhitelabelDomainTypes(params, id) {
  return new Promise(function(resolve, reject) {
    wretch(`/api/v1/whitelabel_domains/${id}/whitelabel_types`)
      .post(params)
      .json(resolve)
      .catch(reject);
  });
}

export function getWhitelabelDomains() {
  return new Promise(function(resolve, reject) {
    wretch('/api/v1/whitelabel_domains').get().json(resolve).catch(reject);
  });
}

export function showWhitelabelDomain(id) {
  return new Promise(function(resolve, reject) {
    wretch(`/api/v1/whitelabel_domains/${id}`).get().json(resolve).catch(reject);
  });
}

export function verifyWhitelabelDomain(id) {
  return new Promise(function(resolve, reject) {
    wretch(`/api/v1/whitelabel_domains/${id}/verify`)
      .post()
      .json(resolve)
      .catch(reject);
  });
}

export function setNotPrimaryWhitelabelDomain(id) {
  return new Promise(function(resolve, reject) {
    wretch(`/api/v1/whitelabel_domains/${id}/primary`)
      .delete()
      .res(resolve)
      .catch(reject);
  });
}

export function setPrimaryWhitelabelDomain(id) {
  return new Promise(function(resolve, reject) {
    wretch(`/api/v1/whitelabel_domains/${id}/primary`)
      .post()
      .res(resolve)
      .catch(reject);
  });
}

export function deleteWhitelabelDomainRecords(id) {
  return new Promise(function(resolve, reject) {
    wretch(`/api/v1/whitelabel_types/${id}`)
      .delete()
      .res(resolve)
      .catch(reject);
  });
}

export function getBranding() {
  return new Promise(function(resolve, reject) {
    wretch('/api/v1/branding')
      .get()
      .json(resolve)
      .catch(reject);
  });
}

export function updateBranding(params) {
  return new Promise(function(resolve, reject) {
    wretch('/api/v1/branding')
      .body(params)
      .put()
      .res(resolve)
      .catch(reject);
  });
}

export function updateExperience(id, params = {}) {
  return new Promise(function(resolve, reject) {
    wretch(`/api/v1/experiences/${id}`)
      .put(params)
      .json(resolve)
      .catch(reject);
  });
}

export function deleteExperience(id) {
  return new Promise(function(resolve, reject) {
    wretch(`/api/v1/experiences/${id}`)
      .delete()
      .res(resolve)
      .catch(reject);
  });
}

export function deleteExperienceByPath(url) {
  return new Promise(function(resolve, reject) {
    wretch(url)
      .delete()
      .res(resolve)
      .catch(reject);
  });
}

export function hideFormByPath(url, params) {
  return new Promise(function(resolve, reject) {
    wretch(url)
      .put(params)
      .res(resolve)
      .catch(reject);
  });
}

export function createExperiencesFromTemplate(tempateId) {
  return new Promise(function(resolve, reject) {
    wretch(`/api/v1/templates/${tempateId}/dup_groups`)
      .post({ template_id: tempateId })
      .res(resolve)
      .catch(reject);
  });
}

export function createChatbotPhoneNumber(params) {
  return new Promise(function(resolve, reject) {
    wretch('/api/v1/chatbot_phone_numbers')
      .post(params)
      .json(resolve)
      .catch(reject);
  });
}

export function deleteChatbotPhoneNumber(id) {
  return new Promise(function(resolve, reject) {
    wretch(`/api/v1/chatbot_phone_numbers/${id}`)
      .delete()
      .res(resolve)
      .catch(reject);
  });
}

export function getChatbotKeywords() {
  return new Promise(function(resolve, reject) {
    wretch('/api/v1/experiences/chatbot_keywords')
      .get()
      .json(resolve)
      .catch(reject);
  });
}

export function updateChatbotKeywords(params) {
  return new Promise(function(resolve, reject) {
    wretch('/api/v1/experiences/chatbot_keywords')
      .put(params)
      .res(resolve)
      .catch(reject);
  });
}

export function reorderBlocks(experienceUuid, blockId, params) {
  return new Promise(function(resolve, reject) {
    wretch(`/api/v1/experiences/${experienceUuid}/blocks/${blockId}`)
      .put(params)
      .json(resolve)
      .catch(reject);
  });
}

export function duplicateBlock(experienceUuid, blockId) {
  return new Promise(function(resolve, reject) {
    wretch(`/api/v1/experiences/${experienceUuid}/blocks/${blockId}/duplicate`)
      .put()
      .json(resolve)
      .catch(reject);
  });
}

export function getChatbotPhoneNumbers() {
  return new Promise(function(resolve, reject) {
    wretch('/api/v1/chatbot_phone_numbers')
      .get()
      .json(resolve)
      .catch(reject);
  });
}

export function deleteBlock(experienceUuid, blockId) {
  return new Promise(function(resolve, reject) {
    wretch(`/api/v1/experiences/${experienceUuid}/blocks/${blockId}`)
      .delete()
      .res(resolve)
      .catch(reject);
  });
}

export function updateMedia(askUuid, params) {
  return new Promise(function(resolve, reject) {
    wretch(`/api/v1/asks/${askUuid}/media`)
      .put(params)
      .res(resolve)
      .catch(reject);
  });
}

export function deleteMedia(askUuid) {
  return new Promise(function(resolve, reject) {
    wretch(`/api/v1/asks/${askUuid}/media`)
      .delete()
      .res(resolve)
      .catch(reject);
  });
}

export function autopilotSessionOptOut(id) {
  return new Promise(function(resolve, reject) {
    wretch(`/api/v1/autopilot_sessions/${id}/opt_out`)
      .post()
      .res(resolve)
      .catch(reject);
  });
}

export function getResponseViewItems(params, responseViewId) {
  return new Promise(function(resolve, reject) {
    wretch(`/api/v1/response_views/${responseViewId}/items`)
      .query(params)
      .get()
      .json(resolve)
      .catch(reject);
  });
}

export function updateResponseViewSortingConfig(params, responseViewId) {
  return new Promise(function(resolve, reject) {
    wretch(`/api/v1/response_views/${responseViewId}`)
      .put(params)
      .res(resolve)
      .catch(reject);
  });
}

export function createResponseViewFilter(params, responseViewId) {
  return new Promise(function(resolve, reject) {
    wretch(`/api/v1/response_views/${responseViewId}/filters`)
      .post(params)
      .json(resolve)
      .catch(reject);
  });
}

export function updateResponseViewFilter(params, responseViewId, filterId) {
  return new Promise(function(resolve, reject) {
    wretch(`/api/v1/response_views/${responseViewId}/filters/${filterId}`)
      .put(params)
      .res(resolve)
      .catch(reject);
  });
}

export function deleteResponseViewFilter(responseViewId, filterId) {
  return new Promise(function(resolve, reject) {
    wretch(`/api/v1/response_views/${responseViewId}/filters/${filterId}`)
      .delete()
      .res(resolve)
      .catch(reject);
  });
}

// Response view
export function getResponseViews() {
  return new Promise(function(resolve, reject) {
    wretch('/api/v1/response_views')
      .get()
      .json(resolve)
      .catch(reject);
  });
}

export function getResponseView(responseViewId) {
  return new Promise(function(resolve, reject) {
    wretch(`/api/v1/response_views/${responseViewId}`)
      .get()
      .json(resolve)
      .catch(reject);
  });
}

export function createResponseView(params) {
  return new Promise(function(resolve, reject) {
    wretch('/api/v1/response_views/')
      .post(params)
      .json(resolve)
      .catch(reject);
  });
}

export function updateResponseView(responseViewId, params) {
  return new Promise(function(resolve, reject) {
    wretch(`/api/v1/response_views/${responseViewId}`)
      .put(params)
      .res(resolve)
      .catch(reject);
  });
}

export function deleteResponseView(responseViewId) {
  return new Promise(function(resolve, reject) {
    wretch(`/api/v1/response_views/${responseViewId}`)
      .delete()
      .res(resolve)
      .catch(reject);
  });
}

export function resetResponseView(responseViewId) {
  return new Promise(function(resolve, reject) {
    wretch(`/api/v1/response_views/${responseViewId}/reset`)
      .post()
      .res(resolve)
      .catch(reject);
  });
}

export function duplicateResponseView(params, responseViewId) {
  return new Promise(function(resolve, reject) {
    wretch(`/api/v1/response_views/${responseViewId}/duplicate`)
      .post(params)
      .json(resolve)
      .catch(reject);
  });
}

// Response view columns
export function updateResponseViewColumn(params, responseViewId, columnId) {
  return new Promise(function(resolve, reject) {
    wretch(`/api/v1/response_views/${responseViewId}/columns/${columnId}`)
      .put(params)
      .res(resolve)
      .catch(reject);
  });
}

export function deleteResponseViewColumn(responseViewId, columnId) {
  return new Promise(function(resolve, reject) {
    wretch(`/api/v1/response_views/${responseViewId}/columns/${columnId}`)
      .delete()
      .res(resolve)
      .catch(reject);
  });
}

export function deleteCustomEmail(id) {
  return new Promise(function(resolve, reject) {
    wretch(`/api/v1/actions/custom_emails/${id}`)
      .delete()
      .res(resolve)
      .catch(reject);
  });
}

export function getEmailMediaUrl(id) {
  return new Promise(function(resolve, reject) {
    wretch(`/api/v1/actions/email_media_url/${id}`)
      .get()
      .json(resolve)
      .catch(reject);
  });
}

export function updateTransition(blockId, params) {
  return new Promise(function(resolve, reject) {
    wretch(`/api/v1/blocks/${blockId}/transition`)
      .put(params)
      .json(resolve)
      .catch(reject);
  });
};

export function getEmailTemplates() {
  return new Promise(function(resolve, reject) {
    wretch('/api/v1/email_templates')
      .get()
      .json(resolve)
      .catch(reject);
  });
}

export function getWorkspaceEmailTemplates() {
  return new Promise(function(resolve, reject) {
    wretch('/api/v1/workspace_email_templates')
      .get()
      .json(resolve)
      .catch(reject);
  });
}

export function createWorkspaceEmailTemplate(params) {
  return new Promise(function(resolve, reject) {
    wretch('/api/v1/workspace_email_templates')
      .post(params)
      .res(resolve)
      .catch(reject);
  });
}

export function updateWorkspaceEmailTemplate(id, params) {
  return new Promise(function(resolve, reject) {
    wretch(`/api/v1/workspace_email_templates/${id}`)
      .put(params)
      .res(resolve)
      .catch(reject);
  });
}

export function duplicateWorkspaceEmailTemplate(id) {
  return new Promise(function(resolve, reject) {
    wretch(`/api/v1/workspace_email_templates/${id}/duplicate`)
      .post()
      .json(resolve)
      .catch(reject);
  });
}

export function deleteWorkspaceEmailTemplate(id) {
  return new Promise(function(resolve, reject) {
    wretch(`/api/v1/workspace_email_templates/${id}`)
      .delete()
      .res(resolve)
      .catch(reject);
  });
}

export function getUnlayerInit() {
  return new Promise(function(resolve, reject) {
    wretch('/api/v1/unlayer/init')
      .get()
      .json(resolve)
      .catch(reject);
  });
}

export function uploadFont(params) {
  return new Promise(function(resolve, reject) {
    wretch('/api/v1/fonts')
      .post(params)
      .res(resolve)
      .catch(reject);
  });
}

export function getFonts() {
  return new Promise(function(resolve, reject) {
    wretch('/api/v1/fonts')
      .get()
      .json(resolve)
      .catch(reject);
  });
}

export function deleteFont(fontId) {
  return new Promise(function(resolve, reject) {
    wretch(`/api/v1/fonts/${fontId}`)
      .delete()
      .res(resolve)
      .catch(reject);
  });
}

export function updateFont(fontId, params) {
  return new Promise(function(resolve, reject) {
    wretch(`/api/v1/fonts/${fontId}`)
      .put(params)
      .res(resolve)
      .catch(reject);
  });
}

export function getPaletteColors() {
  return new Promise(function(resolve, reject) {
    wretch('/api/v1/palette_colors')
      .get()
      .json(resolve)
      .catch(reject);
  });
}

export function createPaletteColor(params) {
  return new Promise(function(resolve, reject) {
    wretch('/api/v1/palette_colors')
      .post(params)
      .res(resolve)
      .catch(reject);
  });
}

export function deletePaletteColor(colorId) {
  return new Promise(function(resolve, reject) {
    wretch(`/api/v1/palette_colors/${colorId}`)
      .delete()
      .res(resolve)
      .catch(reject);
  });
}

export function updatePaletteColor(colorId, params) {
  return new Promise(function(resolve, reject) {
    wretch(`/api/v1/palette_colors/${colorId}`)
      .put(params)
      .res(resolve)
      .catch(reject);
  });
}

export function getIntegrationEntities(integrationId) {
  return new Promise(function(resolve, reject) {
    wretch(`/api/v1/integrations/${integrationId}/integration_entities`)
      .get()
      .json(resolve)
      .catch(reject);
  });
}

export function createIntegrationRefreshes(integrationId) {
  return new Promise(function(resolve, reject) {
    wretch(`/api/v1/integrations/${integrationId}/integration_refreshes`)
      .post()
      .json(resolve)
      .catch(reject);
  });
}
